/*
 Värvid numbriga lõpus lähevad numbri kasvades tumedamaks

 Kõik kasutatavad värvid peaks olema siin file'is, isegi native'id nagu black, white jne
*/

:root {
  /* root värvid */
  --col-dark-1:     #7e8a81;
  --col-dark-2:     #617466;
  --col-white-0:    white;
  --col-white-1:    #fdfffe;
  --col-white-2:    #f2f5f2;
  --col-gray-1:     #cccccc;
  --col-green-1:    #acb999;
  --col-lime-1:     #dae67c;
  --col-lime-2:     #ccd876;
  --col-black:      black;

  /* nuppud */
  --col-btn-add:    #545454;
  --col-btn-hover:  #5a6268;
  --col-btn-del:    #967c6a;
  --col-btn-filter: #4a4f69;

  /* misc */
  --col-background-body:             #e6e9e5;
  --col-link:                        #728752;
  --col-link-hover:                  #0D4350;
  --col-text-on-white:               #444444;
  --col-tr-hover:                    #f8feeb;
  --col-panel:                       #cbd5c8;
  --col-sec-panel:                   #bdcbbd;
  --col-background-disabled:         lightgray;
  --col-ex-data-header:              #d1dfd1;
  --col-background-menu-link-hover:  #929e95;
  --col-background-menu-link-active: #cad4c7;
  --col-nav-link-hover:              rgba(0, 0, 0, 0.25);
  --col-danger:                      #dc5b5b;
  --col-danger-text:                 #5c0000;
}
