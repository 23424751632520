@import "colors.css";

@font-face {
  font-family: "Roboto-Regular";
  font-style:  normal;
  font-weight: normal;
  src:         local("Roboto-Regular"), url("../fonts/Roboto-Regular.ttf") format("truetype");
}

html, body {
  background:        url("../images/card_pattern.png"), var(--col-background-body);
  background-size:   55px;
  background-repeat: repeat;
  font-family:       "Roboto-Regular", Helvetica, "DejaVu Sans", Arial, sans-serif;
  font-size:         0.94rem;
}

a {
  color: var(--col-link);
}

a:hover {
  color:           var(--col-link-hover);
  text-decoration: none;
}

li.active > a {
  color: var(--col-white-0);
}

thead > tr {
  border-style: hidden !important;
}

thead > tr > th {
  background-color: var(--col-dark-1) !important;
  color:            var(--col-white-0) !important;
  position:         -webkit-sticky; /* Safari */
  position:         sticky;
  top:              0;
}

tbody > tr > td {
  color:          var(--col-text-on-white) !important;
  font-size:      0.92rem;
  vertical-align: middle !important;
}

tr > td:first-child, tr > th:first-child {
  padding-left: 1rem !important;
}

tr > td:last-child, tr > th:last-child {
  padding-right: 1rem !important;
}

tr:nth-child(odd) {
  background-color: var(--col-white-1);
}

tr:nth-child(even) {
  background-color: var(--col-white-2);
}

tr:hover {
  background-color: var(--col-tr-hover);
}

input, select, textarea {
  border:           2pt solid var(--col-green-1) !important;
  border-radius:    3px !important;
  background-color: var(--col-white-1) !important;
  color:            var(--col-text-on-white) !important;
}

input:disabled,
.react-date-picker.background-disabled > .react-date-picker__wrapper,
.react-date-picker.background-disabled > .react-date-picker__wrapper > .react-date-picker__inputGroup {
  background-color: var(--col-background-disabled) !important;
}

.brdr-top {
  border-top: 2px solid var(--col-dark-1) !important;
}

.brdr-bottom {
  border-bottom: 2px solid var(--col-dark-1) !important;
}

.form-deactivated button {
  opacity:        0.5;
  pointer-events: none;
  cursor:         default;
}

.break-word {
  word-wrap: break-word;
}

.panel {
  background-color: var(--col-panel);
}

.menu-panel {
  background-color: var(--col-dark-1);
}

.menu-text {
  color: var(--col-white-1) !important;
}

.sec-panel {
  background-color: var(--col-sec-panel);
}

.dark-col {
  color: var(--col-dark-2);
}

.icon {
  color: var(--col-lime-1) !important;
}

.btn-link {
  color: var(--col-dark-2);
}

.btn-link:hover {
  color: var(--col-link-hover);
}

.sm-btn {
  padding:       .25rem .5rem !important;
  font-size:     .875rem !important;
  line-height:   1.5 !important;
  border-radius: .25rem;
  color:         var(--col-white-0) !important;
}

.sm-btn:hover {
  color:            var(--col-white-0) !important;
  background-color: var(--col-btn-hover) !important;
  border-color:     var(--col-btn-hover);
}

.btn-add,
.btn-active {
  background-color: var(--col-btn-add) !important;
  border-color:     var(--col-btn-add) !important;
}

.btn-edit,
.btn-inactive {
  background-color: var(--col-dark-1) !important;
  border-color:     var(--col-dark-1) !important;
}

.btn-save {
  background-color: var(--col-dark-1) !important;
  border-color:     var(--col-dark-1) !important;
}

.btn-disabled {
  pointer-events: none;
  opacity:        0.5 !important;
}

button:focus {
  box-shadow: unset !important;
}

.btn-filtr {
  background-color: var(--col-btn-filter) !important;
  border-color:     var(--col-btn-filter) !important;
}

.btn-del {
  background-color: var(--col-btn-del) !important;
  border-color:     var(--col-btn-del) !important;
}

.bottom-right {
  position: absolute;
  bottom:   15px;
  right:    15px;
}

.arrow {
  border-color: var(--col-dark-1);
}

.arrow.right {
  transform:          rotate(-45deg);
  transition:         0.5s;
  -webkit-transform:  rotate(-45deg);
  -webkit-transition: 0.5s;
}

.arrow.left {
  transform:          rotate(135deg);
  transition:         0.5s;
  -webkit-transform:  rotate(135deg);
  -webkit-transition: 0.5s;
}

.arrow.up {
  transform:          rotate(-135deg);
  transition:         0.5s;
  -webkit-transform:  rotate(-135deg);
  -webkit-transition: 0.5s;
}

.arrow.down {
  transform:          rotate(45deg);
  transition:         0.5s;
  -webkit-transform:  rotate(45deg);
  -webkit-transition: 0.5s;
}

.filters-toggler {
  display:    inline-block;
  text-align: left;
}

.filter-margin {
  padding-right: 1rem;
  padding-left:  1rem;
}

.scroll-div {
  overflow-y: auto;
  max-height: 250px;
}

.horizontal-scroller {
  overflow-x: auto;
}

.vertical-scroller {
  overflow-y: auto;
  max-height: 750px;
}

.vertical-scroller.vs-medium {
  max-height: 250px !important;
}

.vertical-scroller.vs-large {
  max-height: 500px !important;
}

.vertical-scroller table {
  margin-bottom: unset;
}

.scroll-loader {
  background: var(--col-white-1);
  padding:    0.75rem;
  font-style: italic;
}

.icon-input {
  position: relative;
  display:  inline-block;
}

.icon-input > i {
  position: absolute;
  right:    5px;
  top:      2px;
}

.icon-input:not(.large) > div {
  top:   -2px;
  right: 5px;
}

.icon-input.large > div {
  right: 6px;
  top:   6px;
}

.icon-input.large > div > svg {
  height: 22px;
  width:  22px;
}

.icon-input:not(.large) > input {
  font-weight:   300;
  font-style:    italic;
  font-size:     small;
  padding-left:  5px;
  padding-right: 25px;
}

.icon-input.large > input {
  padding-left:  10px;
  padding-right: 30px;
}

.header-row {
  justify-content: flex-end;
}

.search-col {
  flex-grow:        0;
  background-color: #dde3dc;
  padding-top:      0.45rem;
  min-width:        250px;
  order:            1;
}

.language-choice-col {
  flex-grow:        0;
  padding:          0.75rem;
  background-color: #dde3dc;
  margin-left:      1px;
  display:          flex;
  order:            2;
}

.language-choice-col button {
  color: #d9dfd7;
}

.language-choice-col button.btn-active {
  font-weight: 800;
}

.logout-col {
  flex-grow:        0;
  white-space:      nowrap;
  background-color: #dde3dc;
  margin-left:      1px;
  display:          flex;
  order:            3;
}

@media (max-width: 991px) {
  .search-col {
    flex-basis:       unset;
    padding:          0.5rem 0.75rem;
    background-color: unset;
    order:            4;
  }

  .logout-col {
    border-left:      unset;
    flex-grow:        1;
    justify-content:  flex-end;
    background-color: unset;
  }

  .language-choice-col {
    flex-grow:        1;
    background-color: unset;
  }
}

.void-height {
  height: 50px;
}

.flexbox-column {
  display:   flex;
  flex-flow: column;
}

.required-textarea {
  width:      100%;
  box-shadow: none;
  min-height: 50px;
  max-height: 300px;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.opened-extra-data-header {
  background-color: var(--col-ex-data-header) !important;
}

.opened-extra-data {
  background-color: var(--col-sec-panel) !important;
}

.bold-dark {
  font-weight: bold;
  color:       var(--col-dark-1) !important;
}

.apple-input {
  min-height:    38px !important;
  border-radius: 4px !important;
  border-style:  solid !important;
  border-width:  1px !important;
  border-color:  var(--col-gray-1) !important;
}

.apple-input-min {
  height:        26px !important;
  border-radius: 4px !important;
  border-style:  solid !important;
  border-width:  1px !important;
  border-color:  var(--col-gray-1) !important;
}

input::placeholder {
  opacity: 0.5 !important;
}

.icon-wrapper-width {
  width: 35px;
}

.main-wrapper {
  max-width: 100%;
}

.login-page-wrapper {
  position:  fixed;
  left:      50%;
  top:       50%;
  transform: translate(-50%, calc(-50% - 50px));
}

.login-page-wrapper > .row > .panel {
  box-shadow:                 0 0 12px rgba(255, 255, 255, 0.5);
  border-top-left-radius:     30px;
  border-bottom-right-radius: 30px;
}

@media (max-width: 991px) {
  .login-page-wrapper {
    position:  relative !important;
    transform: unset !important;
    left:      unset !important;
    right:     unset !important;
  }

  .login-page-wrapper > .row > .panel {
    border-top-left-radius:     unset !important;
    border-bottom-right-radius: unset !important;
  }
}

@media (min-width: 992px) {
  .login-page-wrapper {
    max-width: 65% !important;
  }
}

@media (min-width: 1200px) {
  .main-wrapper {
    max-width: 95%;
  }

  .login-page-wrapper {
    max-width: 50% !important;
  }
}

@media (min-width: 1400px) {
  .main-wrapper {
    max-width: 90%;
  }

  .login-page-wrapper {
    max-width: 37.5% !important;
  }
}

@media (min-width: 1600px) {
  .main-wrapper {
    max-width: 85%;
  }

  .login-page-wrapper {
    max-width: 25% !important;
  }
}

@media (max-width: 991px) {
  .menu-padding {
    padding-right:  0 !important;
    padding-left:   0 !important;
    padding-bottom: 15px;
  }

  .page-padding {
    padding-left:  0.25rem !important;
    padding-right: 0.25rem !important;
  }
}

@media (min-width: 992px) {
  .page-padding {
    padding-left:  2rem;
    padding-right: 2rem;
  }
}

.currency-col {
  text-align:    right;
  padding-right: 1rem !important;
}

.label-group-checkbox {
  position:    relative;
  margin-left: 0;
}

.relative-inline-float-right {
  position: relative;
  display:  inline-block;
  float:    right;
}

.position-to-right {
  position: relative;
  float:    right;
}

.position-absolute-tofront {
  position: absolute;
  z-index:  1;
}

.date-picker-toleft {
  left: -558px; /* width of date range picker block */
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.trans-date-input {
  display: inline-block !important;
  width:   180px;
  height:  30px;
  border:  none;
  cursor:  pointer;
}

.select-wrap-required-invisible-input {
  opacity:  0;
  width:    250px;
  height:   0;
  position: absolute;
  z-index:  -1;
}

.css-1g6gooi > div > input { /* override */
  border-width: 0 !important;
}

.css-vj8t7z { /* override */
  border-color: var(--col-green-1) !important;
}

.css-b8ldur-Input > div > input {
  border: none !important;
}

.multiline-badge {
  white-space: pre-wrap;
  text-align:  left;
  line-height: 1.25;
}

.overlay {
  position:         absolute;
  left:             0;
  top:              0;
  width:            100%;
  height:           100%;
  background-color: rgba(203, 213, 200, 0.75);
  z-index:          10;
}

.page-loader-container {
  width:     100px;
  height:    100px;
  position:  absolute;
  top:       50%;
  left:      50%;
  transform: translate(-50%, -50%);
  animation: fadein 1s;
}

.page-loader {
  border:        10px solid var(--col-white-2);
  border-top:    10px solid var(--col-lime-1);
  border-radius: 50%;
  width:         100%;
  height:        100%;
  animation:     spinner 2s linear infinite;
}

.core-container > .container-loader {
  border:        3px solid var(--col-dark-1);
  border-top:    3px solid transparent;
  border-radius: 50%;
  width:         15px;
  height:        15px;
  animation:     spinner 2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.currency-input {
  position: relative;
}

.currency-input > input {
  padding-left: 20px;
}

.currency-input > .currency-symbol {
  position:    absolute;
  top:         8px;
  left:        9px;
  font-weight: 800;
  color:       var(--col-green-1);
}

.z-index-one {
  z-index: 1;
}

.table-checkbox-margin-left { /* override */
  overflow:    hidden !important;
  position:    relative !important;
  margin-left: -0.2rem !important;
}

.overview-container {
  margin:          0.5rem 0;
  display:         flex;
  justify-content: center;
  align-items:     center;
}

.overview-container > .overview-column {
  font-size: 14px;
  padding:   0 15px;
}

.overview-container > .overview-column > .overview-title {
  color:       var(--col-dark-1);
  font-weight: 800;
}

/* MAIN MENU */

.main-menu {
  padding: 0;
}

.main-menu.hidden {
  display: none;
}

@media (min-width: 992px) {
  /* Menüüd saab peita ainult mobiilses vaates */
  .main-menu {
    display: block !important;
  }
}

.main-menu > ul {
  width: 100%;
}

.previous-login-container {
  text-align:    center;
  color:         var(--col-white-0);
  font-size:     0.85rem;
  padding:       0.25rem 0;
  border-bottom: var(--col-lime-1) 1px solid;
}

.previous-login-container > span {
  display: block;
}

.modal-title,
.main-menu > ul > .nav-item {
  width:         100%;
  overflow:      hidden;
  white-space:   nowrap;
  text-overflow: ellipsis;
}

.main-menu > ul .nav-link {
  padding:     0;
  height:      35px;
  line-height: 35px;
}

.main-menu > ul .nav-link:hover {
  background-color: var(--col-background-menu-link-hover);
  border-radius:    0;
}

.main-menu > ul .nav-link.active {
  background-color: var(--col-background-menu-link-active);
  border-radius:    0;
  font-weight:      bold;
}

.main-menu > ul .nav-link.active,
.main-menu > ul .nav-link.active * {
  color: var(--col-dark-1) !important;
}

.main-menu > ul .nav-link > div {
  margin-left:  20px !important;
  margin-right: 10px !important;
}

.menu-panel h1 > a {
  color: var(--col-lime-1);
}

.menu-panel h1 > a:hover {
  color: var(--col-lime-2);
}

/* NAVIGATION TABS */

.nav-tabs {
  border-bottom: 2px solid var(--col-dark-1);
}

.nav-tabs .nav-link {
  cursor:           pointer;
  border:           2px solid var(--col-dark-1) !important;
  border-bottom:    0 !important;
  color:            var(--col-black) !important;
  background-color: var(--col-background-menu-link-active);
}

.nav-tabs .nav-link.active {
  background-color: var(--col-dark-1);
}

.nav-tabs .nav-link:hover {
  background-color: var(--col-nav-link-hover);
}

.nav-tabs > .nav-item {
  margin-bottom: 1px;
}

.nav-tabs > .nav-item:not(:first-child) {
  margin-left: -2px;
}

/* DATEPICKER */

.date-picker-container {
  position:  relative;
  min-width: 150px;
}

.date-picker-container > input {
  padding-right: 22px;
}

.date-picker-container > span.clear-button {
  position: absolute;
  top:      9px;
  right:    9px;
  cursor:   pointer;
}

.date-picker-container > div {
  position: absolute;
  z-index:  25;
}

.date-picker-container > div.pos-left {
  left: 0;
}

.date-picker-container > div.pos-right {
  right: 0;
}

.date-picker-input-min-width {
  min-width: 50px;
}

/* COMMON */

.ml-2-children > * {
  margin-left: 0.5rem !important;
}

.my-1-children > * {
  margin-bottom: 0.25rem !important;
}

/* REACT-DATE-PICKER */

.react-date-picker /* override */
{
  display: block;
}

.react-date-picker__wrapper /* override */
{
  height:           38px !important;
  border-radius:    4px !important;
  border-style:     solid !important;
  border-width:     1px !important;
  border-color:     var(--col-gray-1) !important;
  background-color: var(--col-white-1) !important;
  color:            var(--col-black) !important;
}

.react-date-picker__inputGroup /* override */
{
  padding: .550rem .70rem !important;
  height:  19px !important;
}

.react-date-picker__inputGroup__input /* override */
{
  border-style: none !important;
}

.react-date-picker__inputGroup__input--hasLeadingZero /* override: hack to allow trailing zeros */
{
  min-width:  .54em !important;
  height:     100% !important;
  position:   relative !important;
  border:     0 !important;
  background: none !important;
  font:       inherit !important;
  box-sizing: initial !important;
}

.user-name {
  color: var(--col-gray-1);
}

.user-name:hover {
  color:  var(--col-white-0);
  cursor: pointer;
}

td.min-width {
  width: 1px;
}

table.transparent-table * {
  background: transparent !important;
}

table.transparent-table th {
  color: var(--col-dark-2) !important;
}

table.transparent-table td {
  color: var(--col-black) !important;
}

.text-underline {
  text-decoration: underline;
}

th > .order-buttons {
  height:         20px;
  width:          13px;
  position:       relative;
  display:        inline-block;
  cursor:         pointer;
  vertical-align: middle;
}

th > .order-buttons.in-progress {
  cursor: wait !important;
}

th > .order-buttons > i.button-asc,
th > .order-buttons > i.button-desc {
  position:     absolute;
  border:       solid var(--col-gray-1);
  border-width: 0 3px 3px 0;
  display:      inline-block;
  padding:      3px;
  margin:       0;
  opacity:      0.4;
  left:         2px;
}

th > .order-buttons > i.button-asc.active,
th > .order-buttons > i.button-desc.active {
  opacity: 1;
}

th > .order-buttons > i.button-asc {
  transform: rotate(45deg);
  bottom:    3px;
}

th > .order-buttons > i.button-desc {
  transform: rotate(-135deg);
  top:       3px;
}

.paginator-btns {
  display:         flex;
  justify-content: space-between;
  background:      var(--col-sec-panel);
  padding:         0.25rem;
  margin:          0.5rem 0;
}

.paginator-btns.light {
  background: var(--col-panel) !important;
}

.paginator-btns button {
  border:        1px solid var(--col-dark-1);
  border-radius: 3px;
  margin:        0.15rem;
  min-width:     2rem;
}

.paginator-btns button.selected {
  transform: scale(1.15);
}

.checkbox-form-group {
  margin-bottom: 0 !important;
}

.overview-row {
  margin-bottom: 0.25rem;
}

.overview-row > .overview-row-title {
  text-align:  right;
  font-weight: bold;
  color:       var(--col-dark-1);
}

.overview-row > .overview-row-value {
  word-break: break-word;
}

.changed-col {
  color:         rgb(57, 158, 57);
  background:    rgba(87, 187, 87, 0.25);
  font-weight:   600;
  position:      relative;
  padding:       0.3rem;
  margin:        -0.3rem -0.2rem;
  border-radius: 10px;
}

.select-input-alert {
  background:              var(--col-danger);
  color:                   var(--col-danger-text);
  display:                 inline-block;
  width:                   100%;
  padding:                 0.25rem 0.25rem 0.5rem 0.25rem;
  margin-bottom:           -0.25rem;
  border-top-left-radius:  3px;
  border-top-right-radius: 3px;
  text-align:              center;
  font-size:               0.9rem;
}

.select-input-alert ~ div > div[class$="-control"] {
  border: 1px solid var(--col-danger);
}

.card-balance-tooltip-content {
  background: var(--col-dark-2) !important;
  color:      var(--col-black) !important;
  padding:    1px !important;
  width:      320px !important;
  font-size:  1rem !important;
}

.semi-transparent {
  opacity: 0.3;
}

.title-bar {
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  margin:          0 1rem;
}

.title-bar > * {
  width: 33%;
}

.title-bar > h1 {
  text-align: center !important;
  margin:     0;
}

@media (min-width: 992px) {
  .title-bar > * {
    width: 0;
  }

  .title-bar > h1 {
    width: 100% !important;
  }

  .title-bar > .menu-toggler,
  .title-bar > .space-filler {
    display: none !important;
  }
}

.kkis-logo {
  background:      url("../images/kkis_logo.png") no-repeat center;
  background-size: contain;
  height:          72px;
  width:           100%;
  display:         inline-block;
  margin:          15px 0 10px 0;
}

.kkis-logo.lg {
  height: 105px;
}

.card-balances-table-col div {
  line-height: 1.2;
  font-size:   0.85rem;
  text-align:  center;
  white-space: nowrap;
}

.memo-title {
  font-size:        0.8rem;
  font-weight:      lighter;
  background-color: var(--col-dark-1);
  color:            var(--col-white-0);
  padding:          0 0.25rem;
  display:          inline-block;
}

.memo-text {
  white-space:   pre-line;
  margin-bottom: 1.5rem;
  border-left:   1px solid var(--col-dark-1);
  padding:       0 0.25rem;
}

td .form-check-input {
  position: relative !important;
}

.thin-line {
  line-height: 1;
  width:       100%;
  margin:      0;
}
